import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../atoms/TextFieldWithAuth';
import { CustomFab } from '../atoms/CustomFabButton';
import { Button } from '../atoms/ButtonWithAuth';
import { Select } from '../atoms/SelectWithAuth';
import * as FileSaver from 'file-saver';
import * as Encoding from 'encoding-japanese';
import { GetApp, SearchOutlined } from '@material-ui/icons';
import CsvExportModal from './CsvExportModal';
import moment from 'moment';
import Redux from '../../redux/ReduxConnector';
import { Grid, MenuItem } from '@material-ui/core';
import {
  ifBasePath,
  defaultProjectId,
  sumamoruProjectTypes,
  energyServicerType,
  getProjectType
} from '../../config/baseConfig';
import { PrefectureEnum, PrefectureJPMap } from '../atoms/address-types';
import LocalStorage, { LocalStorageKey } from '../../utils/LocalStorage';
import { ServicerType } from '../../enums/common/servicer-type';
import { getProjectAndServicerIdFromType } from '../../enums/common/ProjectServicerType';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'var(--color-white)',
      borderRadius: 16,
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.2)',
      width: 1216,
      height: 422,
      margin: '32px auto 0'
    },
    containerHeader: {
      backgroundColor: 'var(--color-admin-key)',
      borderRadius: '16px 16px 0 0',
      margin: '0 auto',
      width: 1216,
      height: 56,
      textAlign: 'left',
      position: 'relative'
    },
    headerTitle: {
      color: 'var(--color-white)',
      fontSize: 20,
      lineHeight: '56px',
      fontWeight: 600,
      marginLeft: 20,
      letterSpacing: '0.5px'
    },
    headerSearchIcon: {
      margin: '0 16px 0 9px',
      verticalAlign: 'sub'
    },
    base: {
      padding: '16px 0',
      display: 'flex',
      textAlign: 'left'
    },
    separater: {
      height: 214,
      width: 544,
      margin: '0 32px'
    },
    halfTextField: {
      width: 268,
      height: 40,
      '& input': {
        background: 'white'
      },
      '& p': {
        fontWeight: 'bold',
        color: 'var(--color-error) !important',
        margin: '8px 0 0 !important'
      }
    },
    textField: {
      width: '100%',
      height: 40,
      '& input': {
        background: 'white'
      },
      '& p': {
        color: '#828282 !important',
        fontSize: 12,
        margin: '8px 0 0 !important'
      }
    },
    selectField: {
      position: 'relative',
      marginTop: 8,
      width: 268,
      height: 40,
      borderColor: '#E0E0E0',
      color: 'var(--color-text)'
    },
    heading: {
      color: 'var(--color-gray-3)',
      fontSize: 12,
      fontWeight: 500
    },
    searchButton: {
      marginTop: 8,
      fontSize: '14px !important',
      fontWeight: 'bold',
      width: '200px !important'
    },
    clearButton: {
      color: 'var(--color-gray-3)',
      fontSize: 14,
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      marginTop: 6
    },
    downloadButton: {
      position: 'absolute',
      right: 24,
      color: 'var(--color-white)',
      letterSpacing: '0.5px',
      top: 11
    },
    downloadText: {
      color: 'var(--color-white)',
      fontWeight: 600,
      fontSize: 14
    },
    searchButtonIcon: {
      marginRight: 8
    },
    phoneNumberHeading: {
      color: 'var(--color-gray-3)',
      fontSize: 12,
      fontWeight: 500,
      marginTop: 24
    }
  })
);

type P = {
  index: any;
  algoliaResponse: Array<any>;
  setAlgoliaResponse: any;
  setAllCount: any; // reponseのhit全件数
  /**
   * 1ページ単位に表示する行数(件数)
   */
  rowsPerPage: number;
  /**
   * 今表示しているページ番号
   */
  page: number;
  onChangePage: any;
};

interface InputItem {
  POST_CODE: string;
  PREFECTURE: string;
  CITY: string;
  ADDRESS: string;
  BUILDING_NAME: string;
  FIRST_NAME_KANA: string;
  LAST_NAME_KANA: string;
  TELL: string;
}

enum InputItemType {
  POST_CODE = 'POST_CODE',
  PREFECTURE = 'PREFECTURE',
  CITY = 'CITY',
  ADDRESS = 'ADDRESS',
  BUILDING_NAME = 'BUILDING_NAME',
  FIRST_NAME_KANA = 'FIRST_NAME_KANA',
  LAST_NAME_KANA = 'LAST_NAME_KANA',
  TELL = 'TELL'
}

const convertInputItemToAlgoliaColumn = (
  columnName: string
): Array<string> | undefined => {
  switch (columnName) {
    case InputItemType.POST_CODE:
      return ['postcode'];
    case InputItemType.CITY:
      return ['city'];
    case InputItemType.ADDRESS:
      return ['address'];
    case InputItemType.BUILDING_NAME:
      return ['buildingName', 'name'];
    case InputItemType.FIRST_NAME_KANA:
    case InputItemType.LAST_NAME_KANA:
      return ['sumamoruData.contracts.customerKanaName'];
    case InputItemType.TELL:
      return ['sumamoruData.contracts.customerPhoneNumber'];
  }
  return undefined;
};

const INITIAL_INPUT_ITEM = {
  POST_CODE: '',
  PREFECTURE: '',
  CITY: '',
  ADDRESS: '',
  BUILDING_NAME: '',
  FIRST_NAME_KANA: '',
  LAST_NAME_KANA: '',
  TELL: ''
};

// enum InputItemTypeForDownload {
//   TARGET_DATE = 'TARGET_DATE',
//   TARGET_SERVICE = 'TARGET_SERVICE'
// }

export enum TargetServiceType {
  CDE = 'CDE',
  OSS = 'OSS'
}

enum CsvType {
  NEW = 'New',
  MODIFY = 'Modify'
}

const RealEstateSearchForm: React.FC<P> = (props: P) => {
  const {
    index,
    // algoliaResponse,
    setAlgoliaResponse,
    setAllCount,
    // rowsPerPage,
    // page,
    onChangePage
  } = props;
  const user = Redux.getStore().user;

  const styles = useStyles({});

  const [parameter, setParameter] = React.useState<InputItem>({
    POST_CODE: LocalStorage.get(LocalStorageKey[InputItemType.POST_CODE]) || '',
    PREFECTURE: '',
    CITY: LocalStorage.get(LocalStorageKey[InputItemType.CITY]) || '',
    ADDRESS: LocalStorage.get(LocalStorageKey[InputItemType.ADDRESS]) || '',
    BUILDING_NAME:
      LocalStorage.get(LocalStorageKey[InputItemType.BUILDING_NAME]) || '',
    FIRST_NAME_KANA:
      LocalStorage.get(LocalStorageKey[InputItemType.FIRST_NAME_KANA]) || '',
    LAST_NAME_KANA:
      LocalStorage.get(LocalStorageKey[InputItemType.LAST_NAME_KANA]) || '',
    TELL: LocalStorage.get(LocalStorageKey[InputItemType.TELL]) || ''
  });
  const [searchParam, setSearchParam] = React.useState<InputItem>({
    POST_CODE: LocalStorage.get(LocalStorageKey[InputItemType.POST_CODE]) || '',
    PREFECTURE: '',
    CITY: LocalStorage.get(LocalStorageKey[InputItemType.CITY]) || '',
    ADDRESS: LocalStorage.get(LocalStorageKey[InputItemType.ADDRESS]) || '',
    BUILDING_NAME:
      LocalStorage.get(LocalStorageKey[InputItemType.BUILDING_NAME]) || '',
    FIRST_NAME_KANA:
      LocalStorage.get(LocalStorageKey[InputItemType.FIRST_NAME_KANA]) || '',
    LAST_NAME_KANA:
      LocalStorage.get(LocalStorageKey[InputItemType.LAST_NAME_KANA]) || '',
    TELL: LocalStorage.get(LocalStorageKey[InputItemType.TELL]) || ''
  });
  const [prefectureFilter, setPrefectureFilter] = React.useState<string>(
    LocalStorage.get(LocalStorageKey[InputItemType.PREFECTURE]) || ''
  );
  const [firstFlg, setFirstFlg] = React.useState(true);

  const updateInputParamter = React.useCallback(
    (type: InputItemType) => (e: any) => {
      setParameter({
        ...parameter,
        [type]: e.target.value
      });
      LocalStorage.set(LocalStorageKey[type], e.target.value as string);
    },
    [parameter]
  );
  const updateSearchParamter = React.useCallback(() => {
    setFirstFlg(false);
    setSearchParam({
      ...parameter
    });
  }, [parameter, setFirstFlg]);

  const updateFilter = React.useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      setPrefectureFilter(e.target.value as string);
      LocalStorage.set(LocalStorageKey.PREFECTURE, e.target.value as string);
    },
    []
  );

  const clearInputParameter = React.useCallback(() => {
    LocalStorage.remove(LocalStorageKey[InputItemType.POST_CODE]);
    LocalStorage.remove(LocalStorageKey[InputItemType.PREFECTURE]);
    LocalStorage.remove(LocalStorageKey[InputItemType.CITY]);
    LocalStorage.remove(LocalStorageKey[InputItemType.ADDRESS]);
    LocalStorage.remove(LocalStorageKey[InputItemType.BUILDING_NAME]);
    LocalStorage.remove(LocalStorageKey[InputItemType.FIRST_NAME_KANA]);
    LocalStorage.remove(LocalStorageKey[InputItemType.LAST_NAME_KANA]);
    LocalStorage.remove(LocalStorageKey[InputItemType.TELL]);
    setParameter(INITIAL_INPUT_ITEM);
    setPrefectureFilter('');
    setFirstFlg(true);
  }, []);

  const [openCsvModal, setOpenCsvModal] = React.useState<boolean>(false);
  const [targetService, setTargetService] = React.useState<ServicerType>(
    energyServicerType
  );
  const [targetDate, setTargetDate] = React.useState<string>(
    moment()
      .add(-1, 'days')
      .format('YYYY-MM-DD')
  );
  const [isCsvError, setIsCsvError] = React.useState<boolean>(false);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const renderOptions = React.useCallback(() => {
    const optionsDOM: any[] = [
      <MenuItem key={'label'} value="" disabled>
        都道府県
      </MenuItem>
    ];
    for (const prefectureKey of Object.entries(PrefectureEnum)) {
      optionsDOM.push(
        <MenuItem key={prefectureKey[0]} value={prefectureKey[0]}>
          {PrefectureJPMap[prefectureKey[1]]}
        </MenuItem>
      );
    }
    return optionsDOM;
  }, []);

  useEffect(() => {
    if (user.authType === 'OG_OPERATOR' || user.authType === 'OG_ADMIN') {
      setTargetService(energyServicerType);
    } else if (
      user.authType === 'OSS_OPERATOR' ||
      user.authType === 'OSS_ADMIN'
    ) {
      setTargetService(ServicerType.OSS);
    }
  }, [user.authType, setTargetService]);

  useEffect(() => {
    if (!user.authType || user.authType === 'INVALID') {
      setAlgoliaResponse([]);
      setAllCount(0);
      onChangePage(0);
      return;
    }

    if (firstFlg) {
      return;
    }

    const paramKeys = Object.keys(searchParam).filter(
      key => searchParam[key] && parameter[key].length > 0
    );
    const searchableAttributes = paramKeys
      .map(key => convertInputItemToAlgoliaColumn(key))
      .filter(key => !!key);
    const params = paramKeys.map(key => searchParam[key]);

    let filter: any = {
      'sumamoruData.sumamoruLinkable': ['true'],
      'sumamoruData.sumamoruProjectTypes': sumamoruProjectTypes
    };
    if (
      PrefectureEnum[prefectureFilter] &&
      PrefectureEnum[prefectureFilter] !== PrefectureEnum.UNSELECTED
    ) {
      filter = { ...filter, prefecture: [PrefectureEnum[prefectureFilter]] };
    }

    let excludeFilters: any = {};

    // 今のlogin権限で表示可能なデータを変動させる
    // （※ただし、釣れるのは「物件データ」なので返却される物件に紐づく契約情報は全部付いてくるので再度local filter必要）
    if (user.authType === 'OG_ADMIN' || user.authType === 'OSS_ADMIN') {
      // 制限なし
    } else if (user.authType === 'OG_OPERATOR') {
      // OG様オペレータの場合: applieddeでないかつall_in_useかつ電気契約利用なしは除外
      filter = {
        ...filter,
        'sumamoruData.contracts.applicationStatus': ['applied'],
        'sumamoruData.contracts.sumamoruStatus': ['all_in_use']
        // 'sumamoruData.contracts.hasElectricContract': [false]
      };
    } else if (user.authType === 'OSS_OPERATOR') {
      // OSS様オペレータの場合: appliedでないかつall_canceledのものは除外
      filter = {
        ...filter,
        'sumamoruData.contracts.applicationStatus': ['applied']
      };
      excludeFilters = {
        'sumamoruData.contracts.sumamoruStatus': ['all_canceled']
      };
    }

    /**
     * 条件に一致した契約情報の全件
     */
    let contracts = [];
    /**
     * 取得可能な範囲で全ての物件データを取得(MAX 1000件)してlocalでfilterをかけた後、入れ子になっている契約データを表に取り出して整形する
     *
     * algoliaでの現データ構造上、API経由だと正確な全件データ数が分からない & 正常なページングを実装するのが厳しい
     * ので、「全件(1000件)fetch」を行ってlocalで保持している値をページング時に表示範囲を変動させる
     * （※ 業務的にも1000件hitを出す検索条件を入れることがそもそもないのでこれで足りる）
     */
    const fetchAllData = async () => {
      const algoliaLengthParamMaximum = 1000; // length で設定できるMAX値(algoliaの仕様)
      const response = await index.search({
        searchWord:
          params.length === 0 ? [] : params.reduce((a, b) => `${a} ${b}`),
        restrictSearchableAttributes:
          !!searchableAttributes && searchableAttributes.length > 0
            ? searchableAttributes
                .reduce((a, b) => a!.concat(b!))!
                .filter((x, i, self) => self.indexOf(x) === i)
            : [],
        filters: filter,
        excludeFilters: excludeFilters,
        facets: {},
        numericFilters: [],
        length: algoliaLengthParamMaximum,
        offset: 0 // ここが1000になったとしてもalgoliaが探索可能なデータ範囲がMAX1000なので、意味がない
      });

      const resultsList = response.hits
        .filter(
          hit =>
            !!hit.sumamoruData &&
            !!hit.sumamoruData.contracts &&
            hit.sumamoruData.contracts.length > 0
        )
        .map(hit =>
          // 物件に複数契約が付く都合上、対象外の契約も含まれてきてしまうので再度ここでもfilterが必要
          hit.sumamoruData.contracts
            // OG様オペレータの場合でapplieddeでないかつall_in_useかつ電気契約利用なしは除外
            .filter(
              contract =>
                user.authType === 'OSS_OPERATOR' ||
                user.authType === 'OG_ADMIN' ||
                user.authType === 'OSS_ADMIN' ||
                (contract.applicationStatus === 'applied' &&
                  contract.sumamoruStatus === 'all_in_use')
              // && contract.hasElectricContract)
            )
            // OSS様オペレータの場合でappliedでないつall_canceledのものは除外
            .filter(
              contract =>
                user.authType === 'OG_OPERATOR' ||
                user.authType === 'OG_ADMIN' ||
                user.authType === 'OSS_ADMIN' ||
                (contract.applicationStatus === 'applied' &&
                  contract.sumamoruStatus !== 'all_canceled')
            )
            .map(contract => ({
              base: hit,
              sumamoruData: hit.sumamoruData,
              contract: contract
            }))
        );
      index.clearCache();

      if (resultsList.length !== 0) {
        contracts = resultsList.reduce((a, b) => a.concat(b)); // 1物件に複数契約つく場合があり、画面に表示するのは1契約毎
      }
    };

    fetchAllData()
      .then(res => {
        setAlgoliaResponse(contracts);
        setAllCount(contracts.length); // 1物件に複数契約が出る都合上、nbHitsでは取得不可能
        onChangePage(0);
      })
      .catch(e => console.log(e));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, searchParam]);

  const download = React.useCallback(
    (csvType: CsvType) => (e: any) => {
      if (!targetService) {
        return;
      }
      setProcessing(true);
      // /api/v1/application/csv/download/{servicer}/{csv_type}/{target_date}
      // const url = `${ifBasePath}application/${targetService}/download/${targetDate}/${targetType}`;

      const projectServicerId = getProjectAndServicerIdFromType(
        getProjectType(defaultProjectId),
        targetService
      );

      const url = `${ifBasePath}application/csv/download/${projectServicerId}/${csvType}/${targetDate}`;
      fetch(url)
        .then(result => {
          if (!result.ok) {
            throw Error('Fetch Error');
          }
          result
            .text()
            .then(csvData => {
              const unicodeList = [];
              for (let i = 0; i < csvData.length; i += 1) {
                // @ts-ignore
                unicodeList.push(csvData.charCodeAt(i));
              }

              // 変換処理の実施
              const shiftJisCodeList = Encoding.convert(
                unicodeList,
                'sjis',
                'unicode'
              );
              const uInt8List = new Uint8Array(shiftJisCodeList);

              // csv書き込みの実装
              const writeData = new Blob([uInt8List], { type: 'text/csv' });

              // ファイル名の定義
              const fileName = `${targetService}_${csvType.toUpperCase()}_${targetDate.replace(
                /-/g,
                ''
              )}.csv`;

              FileSaver.saveAs(writeData, fileName);
              setProcessing(false);
            })
            .catch(e => {
              console.log('error: ', e);
              setProcessing(false);
            });
        })
        .catch(e => {
          setIsCsvError(true);
          console.log('error: ', e);
          setProcessing(false);
        });
      return;
    },
    [targetService, targetDate, setIsCsvError]
  );

  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <span className={styles.headerTitle}>
          <SearchOutlined className={styles.headerSearchIcon} />
          物件検索
        </span>
        <Button
          className={styles.downloadButton}
          startIcon={<GetApp />}
          onClick={() => setOpenCsvModal(true)}
        >
          <span className={styles.downloadText}>CSVエクスポート</span>
        </Button>
      </div>
      <div className={styles.base}>
        <div className={styles.separater}>
          <p className={styles.heading}>住所</p>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CustomTextField
                key={'postcode'}
                className={styles.halfTextField}
                placeholder="郵便番号"
                margin={'dense'}
                InputLabelProps={{
                  shrink: true
                }}
                value={parameter.POST_CODE}
                variant="outlined"
                onChange={updateInputParamter(InputItemType.POST_CODE)}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                key={'prefecture'}
                className={styles.selectField}
                labelId="prefecture-select"
                value={prefectureFilter}
                variant="outlined"
                onChange={updateFilter}
                displayEmpty
              >
                {renderOptions()}
              </Select>
            </Grid>
          </Grid>
          <CustomTextField
            key={'city'}
            className={styles.textField}
            placeholder="市町村"
            margin={'dense'}
            InputLabelProps={{
              shrink: true
            }}
            value={parameter.CITY}
            variant="outlined"
            onChange={updateInputParamter(InputItemType.CITY)}
          />
          <CustomTextField
            key={'address'}
            className={styles.textField}
            placeholder="番地"
            margin={'dense'}
            InputLabelProps={{
              shrink: true
            }}
            value={parameter.ADDRESS}
            variant="outlined"
            onChange={updateInputParamter(InputItemType.ADDRESS)}
          />
          <CustomTextField
            key={'building'}
            className={styles.textField}
            placeholder="建物名"
            margin={'dense'}
            InputLabelProps={{
              shrink: true
            }}
            value={parameter.BUILDING_NAME}
            variant="outlined"
            onChange={updateInputParamter(InputItemType.BUILDING_NAME)}
          />
        </div>
        <div className={styles.separater}>
          <p className={styles.heading}>契約者氏名 カナ</p>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CustomTextField
                key={'firstname'}
                className={styles.halfTextField}
                placeholder="セイ"
                margin={'dense'}
                InputLabelProps={{
                  shrink: true
                }}
                value={parameter.FIRST_NAME_KANA}
                onChange={updateInputParamter(InputItemType.FIRST_NAME_KANA)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                key={'lastname'}
                className={styles.halfTextField}
                placeholder="メイ"
                margin={'dense'}
                InputLabelProps={{
                  shrink: true
                }}
                value={parameter.LAST_NAME_KANA}
                onChange={updateInputParamter(InputItemType.LAST_NAME_KANA)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <p className={styles.phoneNumberHeading}>電話番号</p>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <CustomTextField
                key={'tell'}
                className={styles.textField}
                placeholder="電話番号"
                margin={'dense'}
                InputLabelProps={{
                  shrink: true
                }}
                value={parameter.TELL}
                onChange={updateInputParamter(InputItemType.TELL)}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <CustomFab
        variant={'extended'}
        size={'large'}
        aria-label={'add'}
        className={styles.searchButton}
        onClick={updateSearchParamter}
      >
        <SearchOutlined
          className={styles.searchButtonIcon}
          fontSize={'small'}
        />
        検索
      </CustomFab>
      <div>
        <Button className={styles.clearButton} onClick={clearInputParameter}>
          検索条件のクリア
        </Button>
      </div>
      <CsvExportModal
        isOpen={openCsvModal}
        setIsOpen={setOpenCsvModal}
        onClickNewData={download(CsvType.NEW)}
        onClickModifyData={download(CsvType.MODIFY)}
        targetDate={targetDate}
        setTargetDate={setTargetDate}
        targetService={targetService}
        isError={isCsvError}
        setIsError={setIsCsvError}
        processing={processing}
        setProcessing={setProcessing}
      />
    </div>
  );
};

export default RealEstateSearchForm;
