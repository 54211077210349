import { ServicerType } from "../enums/common/servicer-type";
import { SumamoruProjectType } from "../enums/common/sumamoru-project-type";
import { AlgoliaConfigType, AlgoliaConfigTableUnit } from "./algoliaConfigType";


export const getProjectId = (type: SumamoruProjectType): string => {
  switch (type) {
    case "CDE":
      return "48566ff5-3e7c-4c1f-ac2f-73cef2009f64";
    case "KeiyouGas":
      return "7a36af26-34c3-4ef7-9920-f876cde2bc44";
    case "SeibuGas":
      return "48566ff5-3e7c-4c1f-ac2f-73cef2009f64";
  }
  throw new Error(`there is no type: ${type}`);
};

export const getProjectType = (id: string): SumamoruProjectType => {
  const key = Object.values(SumamoruProjectType).find((key) => {
    try {
      return getProjectId(key) == id;
    } catch (e) {
      return false;
    }
  });
  if (!key) {
    throw new Error(`there is no id: ${id}`);
  }
  return key;
};

export const defaultProjectId = getProjectId(SumamoruProjectType.KeiyouGas);
export const sumamoruProjectTypes = [SumamoruProjectType.KeiyouGas];
export const energyServicerType = ServicerType.KeiyouGas;

/**
 * 以下環境差分
 */
export const region = "ap-northeast-1";
export const stage = "staging";
export const bkpPath = "https://edge.bitkey.tokyo/v1";
export const ifBasePath = "https://staging.api.cde.og.bitlock.xyz/link/api/v1/";
export const bmResidence =
  "https://us-central1-bitlock-staging.cloudfunctions.net/ext-vo/";

const AlgoliaAllSpaceAccount: AlgoliaConfigTableUnit = {
  indexName: "stg_allSpaces",
  applicationId: "7TOIJPY60X",
  searchOnlyApiKey: "cd69788c731787361af0435ecad41ed7",
  adminApiKey: "cd69788c731787361af0435ecad41ed7",
  monitoringApiKey: "cd69788c731787361af0435ecad41ed7",
};

export const AlgoliaConfig: AlgoliaConfigType = {
  AllSpace: AlgoliaAllSpaceAccount,
};